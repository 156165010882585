<style lang="scss" scoped>
</style>

<template>
  <!-- 用户新增/编辑 -->
  <section class="page-child-contianer">
    <el-form
      class="page-form"
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="84px"
    >
      <el-form-item label="上级权限:">
        <el-select v-model="formData.pid">
          <el-option label="无" :value="0" />
          <el-option
            v-for="item in menuList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <p>
              <template v-if="item.hierarchy > 0">
                <span
                  class="hierarchy iconfont"
                  v-for="num in item.hierarchy"
                  :key="num"
                >
                  &#xe603;
                </span>
              </template>
              {{ item.name }}
            </p>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属系统:" prop="system_name">
        <el-select v-model="formData.system_name" placeholder="所属系统">
          <el-option
            v-for="item in systemList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="权限名称:" prop="name">
            <el-input v-model="formData.name" placeholder="请输入权限名称" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="权限类型:" prop="is_menu">
            <el-radio-group v-model="formData.is_menu">
              <el-radio :label="1">菜单</el-radio>
              <el-radio :label="2">按钮</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="前台图标:" prop="front_icon">
            <el-input
              v-model="formData.front_icon"
              placeholder="请输入前台图标（字符串）"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="前端路由地址:" prop="front_route_url">
            <el-input
              v-model="formData.front_route_url"
              placeholder="请输入前端路由地址"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="前端路由名称:" prop="front_route_name">
            <el-input
              v-model="formData.front_route_name"
              placeholder="请输入前端路由名称"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="模块名称:" prop="module_name">
            <el-input
              v-model="formData.module_name"
              placeholder="请输入模块名称"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="控制器名称:" prop="controller_name">
            <el-input
              v-model="formData.controller_name"
              placeholder="请输入控制器名称"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="操作名称:" prop="action_name">
            <el-input
              v-model="formData.action_name"
              placeholder="请输入操作名称"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="后端路由地址:" prop="route_url">
            <el-input
              v-model="formData.route_url"
              placeholder="请输入后端路由地址"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="前端小权限标识:" prop="front_small_role_marks">
        <el-select
          v-model="formData.front_small_role_marks"
          placeholder="前端小权限标识"
        >
          <el-option
            v-for="item in frontSmallRoleMarks"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序:" prop="sort">
        <el-input-number v-model="formData.sort" :min="1" />
      </el-form-item>
      <el-form-item label="备注:">
        <el-input
          v-model="formData.remarks"
          placeholder="请输入权限备注"
          :rows="1"
          maxlength="20"
          show-word-limit
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="handleSubmit"> 确 定 </el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validPhone } from "@utils/validate"; //引入手机号码校验规则
import ueditorcom from "@/components/ueditorcom";
import { roleUpdate, roleAdd } from "@api/role";
export default {
  name: "roleForm",
  inject: ["reload"],
  /**
   * 组件
   */
  components: {
    ueditorcom,
  },
  data() {
    // 校验手机号码
    let phoneValidator = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!validPhone(value)) {
          callback(new Error("手机号码格式错误！"));
        }
        callback();
      }
    };
    return {
      id: null, //修改用户id
      imageUrl: "", //图片上传成功回显链接
      formData: {
        checkList: [],
        system_name: "index",
        module_name: "index",
        status: 1,
        pid: null,
        is_menu: 1,
        sort: 200,
      }, //表单存储数据
      systemList: [
        {
          value: "index",
          name: "后台",
        },
        {
          value: "",
          name: "公共",
        },
      ],
      menuList: [],
      frontSmallRoleMarks: [
        {
          value: "",
          name: "无",
        },
        {
          value: "add",
          name: "新增",
        },
        {
          value: "add_company",
          name: "新增单位",
        },
        {
          value: "add_project",
          name: "新增项目",
        },
        {
          value: "update",
          name: "修改",
        },
        {
          value: "update_company",
          name: "修改单位",
        },
        {
          value: "update_project",
          name: "修改项目",
        },
        {
          value: "delete",
          name: "删除",
        },
        {
          value: "delete_company",
          name: "删除单位",
        },
        {
          value: "delete_project",
          name: "删除项目",
        },
        {
          value: "top",
          name: "置顶/取消置顶",
        },
        {
          value: "pwd_reset",
          name: "重置密码",
        },
        {
          value: "member_assign",
          name: "人员分配",
        },
        {
          value: "role_assign",
          name: "权限分配",
        },
        {
          value: "range_data",
          name: "数据范围",
        },
        {
          value: "detail",
          name: "详情",
        },
        {
          value: "disable",
          name: "禁用",
        },
        {
          value: "mobile_update",
          name: "手机号变更",
        },
        {
          value: "status_update",
          name: "状态变更",
        },
        {
          value: "examine",
          name: "审核",
        },
        {
          value: "down",
          name: "下载",
        },
        {
          value: "import",
          name: "导入",
        },
        {
          value: "export",
          name: "导出",
        },
        {
          value: "flow_design",
          name: "流程配置",
        },
        {
          value: "form_design",
          name: "表单配置",
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入权限名称", trigger: "blur" }],
      }, //表单校验规则
    };
  },
  computed: {
    ...mapState("system", ["menuListSearch", "menuTreeList"]),
  },
  watch: {
    menuListSearch: {
      immediate: true,
      handler(val) {
        this.menuList = val;
      },
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id);
      this.getDetail();
    }
    if (this.$route.params.pid) {
      this.formData.pid = parseInt(this.$route.params.pid);
    }
  },
  mounted() {},
  methods: {
    /** 修改前获取详情信息 */
    async getDetail() {
      /**
       * 请求接口获取详情数据
       */
      let res = await roleUpdate({ id: this.id }, "get");
      res.data.status = Number(res.data.status);
      res.data.password = "";
      this.formData = {
        ...this.formData,
        ...res.data,
      };
      this.imageUrl = res.data.thumb_path;

      //请求返回成功后打开弹窗
      this.formDialogVisible = true;
    },
    /** 取消用户编辑 */
    handleCancle() {
      this.$refs["formData"].clearValidate();
      this.$router.go(-1);
    },
    /** 提交用户信息*/
    handleSubmit() {
      this.formData = {
        ...this.formData,
      };
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          /**
           * 请求接口
           */
          // delete this.formData.delete_time;
          // delete this.formData.update_time;
          if (this.id) {
            // 存在id，执行编辑请求
            var res = await roleUpdate(this.formData, "post");
          } else {
            // 不存在id，执行添加请求
            var res = await roleAdd(this.formData);
          }
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            // 请求后，不论是否成功，调用方法关闭弹窗

            this.handleCancle();
          }
        }
      });
    },
    /** 上传前处理 */
    uploadBefore(file) {
      const isImg =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png" ||
        file.type == "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImg) {
        this.$message.error("上传图片只能是 .jpg,.jpeg,.png,.gif 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isImg && isLt2M;
    },
    /** 上传成功 */
    uploadSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.formData = {
        ...this.formData,
        thumb: res.data.list[0].id,
      };
    },
    /** 上传失败 */
    uploadError(err, file) {
      this.$message.warning("上传失败");
    },
  },
};
</script>